// grid breakpoints
$grid-breakpoints: (xs:0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
// grid containers
$container-max-widths: (md: 991px, lg: 1200px, xl: 1200px);

// grid columns
$grid-columns: 12 !default;
$gutter: 30px !default;
$gutter-xs: 40px !default;
// font
$Montserrat: 'Montserrat';
$font-default: sans-serif;
$body-font-size: 14px;
// color
//-----------------------------------------------------------------------------------
$black: #000;
$light: #fff;
$text-color: #5d656b;
$site-color: #0ba560;
$site-color2: #4888f8;
$site-color-dark: #1a1819;
$disabled: #a0adb8;
$blue-dark: #1e54f2;
$blue-light: #ecf3fe;
$green-dark: #02752f;
$red: #df503f;
$yellow: #ffce34;
$green: #0ba560;
$facebook: #4961b5;
$twitter: #55acee;
$gray-light: #d8d8d8;
$linkedin: #0077b5;
$orange: #ff8c00;
