.article-preview {
  padding: 0;
  cursor: pointer;

  &__image-col {
    flex: 0 0 295px;
    padding-right: 0;

    @include media('<md') {
      flex: 0 100%;
      padding-right: 15px;
    }
  }

  &__text-col {
    padding: 56px 56px 0;

    @include media('<md') {
      padding: 30px 41px 0;
    }
  }
  &__image {
    height: 270px;
    width: 100%;
    object-fit: cover;
  }
  &__category-col {
    flex-basis: calc(100% - 105px);
  }
  &__date-col {
    flex-basis: 105px;
  }
  @include media('<md') {
    &__category-col {
      flex-basis: 100%;
    }
    &__date-col {
      flex-basis: 100%;
    }
  }
}

.article-main-image-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 60px;
}
.article-main-image {
  width: 100%;
}

.other-articles {
  position: relative;

  &__list {}

}
.other-articles-item {

  &__image-col {}
  &__text-col {}
  &__image {
    height: 120px;
    width: 100%;
    object-fit: cover;
  }
  &__title {
    font-size: 16px;
    font-weight: 800;
    @include line-height(30px, 16px);
    color: $site-color-dark;
    margin-bottom: 30px;
    height: 60px;
    overflow: hidden;
  }
  &__title-link {
    @include transition;
    color: $site-color-dark;

    &:hover {
      color: $site-color2;
    }
  }
  &__position {
    font-size: 10px;
    font-weight: 800;
    @include line-height(30px, 10px);
    text-transform: uppercase;
    color: $disabled;
  }

  @include media('<md') {
    margin: 15px 0;
  }

  @include media('<sm') {
    &__image-col {
      margin-bottom: 15px;
    }
    &__title {
      margin-bottom: 5px;
    }
  }
}

.social-share {
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    font-size: 20px;
    font-weight: 800;
    @include line-height(30px, 20px);
    color: $site-color-dark;
  }

  &__btn {}

  @include media('<md') {
    padding-bottom: 30px;
    display: block;

    &__text {
      margin-bottom: 30px;
    }
    &__btn {
      margin-bottom: 30px;
    }
  }
}