.btn {
  $this: &;
  @extend %btn-flex;
  @include transition;
  display: inline-flex;
  padding: 15px 15px;
  height: 60px;
  min-width: 170px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  background-color: transparent;
  color: $site-color2;
  @include line-height(30px, 12px);

  &__icon-container {
    height: 30px;
    position: relative;
    margin-right: 15px;
  }
  &__icon {
    font-size: 30px;
    fill: $site-color2;
    @include transition;
  }
  &__text {
  }

  &:hover {
    color: $blue-dark;
  }

  &--bordered-transparent {
    border: 4px solid $blue-light;
    color: $disabled;

    &:hover {
      border-color: $light;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &--green {
    color: $light;
    background-color: $site-color;

    #{$this}__icon {
      fill: $light;
    }

    &:hover {
      color: $light;
      background-color: $green-dark;
    }
  }

  &--blue {
    color: $light;
    background-color: $site-color2;

    #{$this}__icon {
      fill: $light;
    }

    &:hover {
      color: $light;
      background-color: $blue-dark;
    }
  }

  &--white {
    color: $site-color2;
    background-color: $light;

    #{$this}__icon {
      fill: $site-color2;
    }

    &:hover {
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &--blue-light {
    color: $disabled;
    background-color: $blue-light;

    #{$this}__icon {
      fill: $disabled;
    }

    &:hover {
      color: $site-color2;
      background-color: $light;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &--width-100 {
    width: 100%;
    min-width: auto;
  }

  &--width-auto {
    min-width: 0;
  }

  &--width-min {
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
  }
  &--height-min {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
  }

  &--search {
    padding: 0;
    height: 30px;
  }

  &:disabled {
    opacity: 0.7;
    background-color: $disabled;
    color: $light;
  }

  @include media("<md") {
    width: 100%;

    &--width-auto {
      width: auto;
    }
    &--width-min {
      width: auto;
    }
  }
  @include media("<sm") {
    line-height: 1.2;
  }
}

.btn-icon {
  // display: flex;
  justify-content: center;
  align-items: center;
}
.btn-back {
  color: $disabled;
  position: absolute;
  // left: 0;
  top: -90px;

  &:hover {
    color: $disabled;
  }

  .btn__icon {
    fill: $disabled;
  }

  @include media("<lg") {
    position: relative;
    left: auto;
    top: auto;
    margin-bottom: 30px;
  }
}
.btn-favorite {
  min-width: 0;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -90px;

  .btn__icon-container {
    margin-right: 0;
  }
  .btn__icon {
    fill: $disabled;
  }

  &--active {
    .btn__icon {
      fill: $site-color2;
    }
  }

  @include media("<lg") {
    top: 0;
  }
  @include media("<md") {
    position: relative;
    right: auto;
    width: auto;
    margin: 0 auto 30px;
    display: block;
  }
}

.close-btn {
  width: 60px;
  height: 60px;
  min-width: 60px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 30px;
    fill: $site-color2;
  }

  &:hover {
    .icon {
      fill: $blue-dark;
    }
  }
}

.delete-btn {
  .icon {
    fill: $red;
  }
}
.delete-icon {
  fill: $red;
}
.social-share__btn {
  color: $light;

  &:hover {
    color: $light;
  }
}
.btn-facebook {
  background-color: $facebook;
}
.btn-twitter {
  background-color: $twitter;
}
.btn-linkedin {
  background-color: $linkedin;
}