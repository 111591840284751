.teammate {
  position: relative;
  padding-bottom: 30px;

  &__img-container {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 30px;
    display: block;
    overflow: hidden;
  }

  &__img {
    display: block;
    object-fit: fill;
  }

  &__name {
    color: $black;
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 800;
    margin-bottom: 5px;
  }
  &__position {
    color: $disabled;
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}