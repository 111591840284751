.pagination-block {
  position: relative;
}
.pagination-list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-item {

  &--active {
      .pagination-link {
      background-color: $site-color2;
      color: $light;
    }
  }
}
.prev-page {
  margin-right: 30px;

  @include media("<md") {
    margin-right: 15px;
  }
}
.next-page {
  margin-left: 30px;

  @include media("<md") {
    margin-left: 15px;
  }
}
.pagination-link {
  $this: &;
  @include transition;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 12px;
  @include line-height(20px, 12px);
  text-transform: uppercase;
  font-weight: 800;
  color: $disabled;

  &:hover, #{$this}--active {
    background-color: $site-color2;
    color: $light;
  }

  &--text {
    &:hover {
      background-color: transparent;
      color: $site-color2;
    }
  }

  @include media("<md") {
    width: 40px;
    height: 40px;
    font-size: 10px;
    @include line-height(10px, 10px);
  }
}
.disabled {
  .pagination-link {
    pointer-events: none;
    cursor: none;
    opacity: 0.7;

    &:hover, &--active {
      background-color: transparent;
      color: $disabled;
    }
  }
}