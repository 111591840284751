.filter {
  &__label {
    margin: 15px 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: $site-color-dark;
  }

  &__form {
    position: relative;
    width: 100%;

    @include media("<lg") {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 110px 30px 50px 30px;
      background-color: white;
      z-index: 10;
      overflow: auto;

      &--active {
        display: block;
      }
    }
  }

  &__actions {
    margin-top: 30px;
  }

  &__show {
    display: none;
    width: 100%;
    height: 60px;
    margin-bottom: 30px;
    border: solid 4px $blue-light;
    background-color: $light;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-transform: uppercase;
    text-align: center;
    color: $disabled;

    @include transition;

    &:active {
      border-color: $light;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    }

    @include media("<lg") {
      display: block;
    }
  }

  &__close {
    display: none;
    text-align: right;
    padding: 15px;
    width: 100%;
    height: 60px;
    background-color: $blue-light;

    @include media("<lg") {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__close-icon {
    font-size: 30px;
    fill: $disabled;
  }

  &--side {
    .field-group {
      .field-wrap {
        padding-bottom: 0;
      }
    }
  }
}

.filter-mobile-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $light;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.filter-mobile {
  &__header {
    background-color: $blue-light;
    height: 60px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__body {
    height: calc(100% - 60px);
    flex-shrink: 0;
    flex-grow: 0;
    overflow: scroll;
    padding: 30px;
  }
}