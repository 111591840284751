.banner {
  background-image: url("/img/banner-main.jpg");
  background-position: center;
  background-size: cover;
  padding: 130px 0 105px 0;

  &__title {
    position: absolute;
    @include center();
    font-size: 60px;
    font-weight: 800;
    @include line-height(90px, 60px);
    letter-spacing: -2px;
    color: $light;
    width: 100%;

    sup {
      font-size: 9px;
    }

    @include media("<lg") {
      font-size: 30px;
      @include line-height(40px, 30px);
      text-align: center;
    }
  }

  &__icon {
    font-size: 420px;
    opacity: 0.5;
    fill: $site-color;
  }

  @include media("<lg") {
    padding: 95px 0 95px 0;
  }

  @include media("<md") {
    padding: 40px 0 40px 0;
    background-image: url("/img/banner-main-mobile.jpg");
  }
}
.banner-title-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-silverts {
  position: absolute;
  left: 0;
  bottom: -320px;
  width: 380px;
  height: auto;

  @include media("<xl") {
    bottom: -230px;
    width: 300px;
  }
  @include media("<lg") {
    position: unset;
    width: 100%;
    height: auto;
    left: unset;
    bottom: unset;
    margin-top: 20px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.get-connected-container {
  position: absolute;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  padding: 70px 0;

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: $site-color;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);

    @include media("<lg") {
      left: -15px;
      box-shadow: none;
    }
  }

  @include media("<lg") {
    position: relative;
    padding: 0 0;
    transform: translateY(0);
  }
}

.get-connected {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__text {
    font-size: 28px;
    font-weight: 800;
    @include line-height(30px, 28px);
    color: $light;
    text-align: left;
  }
  &__img {
    width: 60px;
    height: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    background-color: rgba($light, 0.2);
  }
  &__icon {
    font-size: 30px;
    fill: $light;
  }

  @include media("<lg") {
    margin: 0 -15px;
    width: 100vw;

    &__text {
      font-size: 16px;
      @include line-height(30px, 16px);
      padding-left: 30px;
    }
  }
}

.mission-card {
  margin: 0 0 60px;

  &__header {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    @include media("<lg") {
      flex-direction: row;
      align-items: center;
    }
  }

  &__icon {
    width: 60px;
    height: 60px;
    position: relative;
    margin: 0 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 60px;
    flex-grow: 0;
    flex-shrink: 0;

    &::before {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba($site-color2, 0.1);
    }

    @include media("<lg") {
      margin: 0 30px 0 0;
    }
  }

  &__img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }

  &__title {
    font-size: 16px;
    font-weight: 800;
    @include line-height(30px, 16px);
    color: $black;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
    @include line-height(30px, 16px);
    color: $text-color;

    b,
    strong {
      font-weight: 800;
    }

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }

  @include media("<lg") {
    margin: 0 0 40px;
  }
}

.sponsors {
  &__list {
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    display: block;
    max-width: 100%;
    max-height: 120px;
    width: auto;
  }
}

.events-container {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    right: calc(100% - 45px);
    top: 0;
    height: 100%;
    width: 100vw;
    background-color: $site-color;
  }

  @include media("<lg") {
    &::before {
      display: none;
    }
  }
}

.events {
  &__list {
    padding: 0 0 30px;
  }

  @include media("<lg") {
    &__list {
      padding: 0 0 0;
    }
  }
}

.event-item {
  $this: &;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &__date {
    width: 90px;
    height: 90px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 90px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $blue-light;
  }
  &__day {
    font-size: 24px;
    font-weight: 800;
    color: $site-color2;
    @include line-height(30px, 24px);
    padding: 5px 0;
  }
  &__month {
    font-size: 10px;
    font-weight: 800;
    color: $disabled;
    @include line-height(15px, 10px);
    text-transform: uppercase;
    padding: 5px 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 800;
    color: $site-color-dark;
    @include line-height(30px, 16px);
    max-height: 120px;
    overflow: hidden;
  }

  &--main {
    margin-bottom: 30px;

    #{$this}__date {
      background-color: $light;
    }
  }

  @include media("<lg") {
    align-items: center;

    &__title {
      font-size: 16px;
      @include line-height(30px, 16px);
    }
  }
  @include media("<md") {
    &__row {
      flex-direction: column;
      align-items: center;
    }
    &__date {
      margin-right: 0px;
      margin-bottom: 30px;
    }
    &__title {
      width: 100%;
      text-align: center;
    }
  }
}

.advertisment-container {
  position: relative;
  z-index: 1;
  padding: 0 0 70px;

  @include media("<lg") {
    padding: 0 15px 40px;
  }
}
.advertisment-bg-container {
  position: absolute;
  z-index: 0;
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  align-items: stretch;

  .row {
    height: 100%;
  }
}
.advertisment-bg {
  position: absolute;
  right: -30px;
  top: 0;
  width: 100vw;
  height: 100%;

  @include media("<lg") {
    right: 0px;
  }
}
.advert-slide {
  position: relative;
}
.advert-img {
  display: block;
  width: 100%;
}

.news-container {
  position: relative;
  padding: 0 0 120px;

  @include media("<lg") {
    padding: 0 15px 90px;
  }
}
.news-slide {
  position: relative;
  display: block;
  opacity: 0.2;

  &.swiper-slide-next,
  &.swiper-slide-prev,
  &.swiper-slide-active {
    opacity: 1;
  }
}
.news-item {
  position: relative;

  &__img-container {
    position: relative;
    width: 100%;
    padding-top: calc(100% * 360 / 370);
  }
  &__img-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__img {
    // display: block;
    object-fit: cover;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(50, 50, 51, 0), #323233);
  }
  &__data {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 60px;
  }
  &__title {
    font-size: 20px;
    font-weight: 800;
    @include line-height(30px, 20px);
    color: $light;
    height: 90px;
    overflow: hidden;
  }
  &__date {
    font-size: 10px;
    font-weight: 800;
    @include line-height(30px, 10px);
    color: rgba($light, 0.5);
  }
}

.whoweare {
  position: relative;

  &::before {
    display: block;
    content: "";
    position: absolute;
    right: calc(100% - 100px);
    top: 100px;
    bottom: 0;
    width: 100vw;
    background-color: $site-color2;

    @include media("<lg") {
      display: none;
    }
  }

  &__img-container {
    position: relative;
    margin-right: -30px;

    @include media("<lg") {
      margin-right: -30px;
      margin-left: -30px;
    }
  }
  &__img {
    width: 100%;
  }

  &__logo {
    margin-bottom: 60px;

    @include media("<lg") {
      margin-bottom: 30px;
    }
  }

  &__content {
    p {
      font-size: 16px;
      @include line-height(30px, 16px);
      color: $text-color;
      font-weight: 600;
      margin-bottom: 30px;

      b,
      strong {
        font-weight: 800;
      }

      @include media("<lg") {
        font-size: 14px;
        @include line-height(30px, 14px);
      }
    }
  }

  &__slider {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    @include media("<lg") {
      margin-bottom: 30px;
      flex-direction: column;
    }
    &-link {
      margin-top: 30px;
      color: $site-color2;
      font-weight: 800;
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .banner-silverts {
    left: unset;
    bottom: unset;
    width: 100%;
    margin-top: 20px;
    height: auto;
  }

  @include media("<lg") {
  }
}

.whoweare-slide {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &__img {
    flex-basis: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 30px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__data {
    flex: 0 0;
    flex-basis: calc(100% - 90px);
    width: calc(100% - 90px);
  }

  &__name {
    font-size: 16px;
    font-weight: 800;
    @include line-height(30px, 16px);
    margin-bottom: 10px;
    color: $site-color-dark;

    @include media("<md") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }

  &__position {
    font-size: 10px;
    font-weight: 800;
    @include line-height(15px, 10px);
    text-transform: uppercase;
    color: $disabled;
  }
}

.benefits-container {
  position: relative;

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: calc(100% - 170px);
    top: 0;
    bottom: -180px;
    width: 100vw;
    background-color: $blue-light;

    @include media("<lg") {
      display: none;
    }
  }
}
