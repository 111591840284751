.field {
  $this: &;
  position: relative;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;

  &:not(.field-select):not(.field-input-file) {
    padding-top: 15px;
  }

  &__phone {
    display: flex;
    width: 100%;
  }

  &__data {
    position: relative;
    width: 100%;
  }

  &__icon-container {
    font-size: 30px;
    position: absolute;
    right: 0;
  }
  &__icon {
    fill: $disabled;
  }

  &__input {
    border: none;
    height: 45px;
    display: flex;
    align-items: center;
    color: $site-color-dark;
    font-size: 12px;
    font-weight: 800;
    @include line-height(30px, 12px);
    width: 100%;
    background-color: transparent;
    padding-bottom: 14px;
    border-bottom: 1px solid $disabled;
    cursor: text;
    padding-right: 15px;
    @include transition;

    &::placeholder {
      @extend %placeholder;
    }
  }

  &__textarea {
    border: none;
    min-height: 45px;
    display: flex;
    align-items: center;
    color: $site-color-dark;
    font-size: 12px;
    font-weight: 800;
    // text-transform: uppercase;
    @include line-height(30px, 12px);
    width: 100%;
    background-color: transparent;
    padding: 0 0 14px 0;
    border-bottom: 1px solid $disabled;
    cursor: text;
    @include transition;

    &::placeholder {
      @extend %placeholder;
      opacity: 0;
    }
  }

  &__input-file {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &--focused {
    #{$this}__input {
      border-color: $site-color2;
    }

    .field-input__label {
      color: $disabled;
    }
  }

  &--filled {
    .field-input__label {
      color: $site-color;
      &.large-label {
        color: $site-color;
      }
    }
  }

  &--focused,
  &--filled {
    #{$this}__input {
      &::placeholder {
        opacity: 0;
      }
    }

    #{$this}__textarea {
      &::placeholder {
        opacity: 0;
      }
    }

    .field-input__label {
      opacity: 1;
      transform: translateY(-15px);
      visibility: visible;
      font-size: 10px;
      @include line-height(15px, 10px);
      &.large-label {
        opacity: 1;
        transform: translateY(-25px);
        visibility: visible;
        font-size: 14px;
        @include line-height(25px, 14px);
      }
    }
  }

  &--error {
    #{$this}__textarea,
    #{$this}__input {
      border-color: $red;
    }
  }

  &--disabled {
    opacity: 0.5;

    .field-input__label {
      color: $disabled;
    }
  }

  &--inactive {
    #{$this}__textarea,
    #{$this}__input {
      border-color: transparent;
    }

    .field-input__label {
      color: $disabled;
    }
  }

  &--borderless {
    #{$this}__input {
      border-color: transparent;
    }
  }

  &.field-checkbox {
    @include media("<lg") {
      padding-top: 5px;
    }
  }
}

.field-select {
  padding-top: 15px;
  display: block;
}

.field-wrap {
  padding-bottom: 30px;

  @include media("<lg") {
    padding-bottom: 15px;
  }
}

.field-input__label {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(0%);
  @include transition;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12px;
  @include line-height(30px, 12px);

  @include media("<lg") {
    font-size: 10px;
    // @include line-height(18px, 10px);
  }

  &.large-label {
    font-size: 20px;
    line-height: 1.5;
    color: $site-color-dark;
  }
}

@media screen and (max-width: 374px) {
  .field-input__label {
    &.initial-offset {
      transform: translateY(-15px);
    }
  }
}

.field-label {
  color: $disabled;
  // font-weight: 800;
  // font-size: 10px;
  // @include line-height(15px, 10px);
  display: block;
  text-align: left;

  &__required {
    color: $red;
    font-size: 13px;
    @include line-height(10px, 13px);
    display: inline-block;
    width: 10px;
    text-align: center;
  }

  &.large-label {
    .field-label__required {
      font-size: 18px;
      @include line-height(16px, 18px);
      display: inline-block;
      width: 16px;
      text-align: center;
    }
  }
}

.field-error {
  color: $red;
  font-size: 10px;
  font-weight: 600;
  @include line-height(15px, 10px);
  position: absolute;
  right: 0;
  bottom: -20px;
  text-transform: uppercase;
  $this: &;

  &--group {
    bottom: 10px;
  }

  @include media("<lg") {
    bottom: -15px;

    #{$this}--group {
      bottom: 5px;
    }
  }
}

.field-annotation {
  color: $disabled;
  font-size: 10px;
  font-weight: 600;
  @include line-height(15px, 10px);
  text-transform: uppercase;
}

%placeholder {
  @include transition;
  font-size: 12px;
  font-weight: 800;
  @include line-height(30px, 12px);
  color: $disabled;
  opacity: 0;
}

// checkbox
.field-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 15px;

  input[type="checkbox"] {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
  input[type="radio"] {
    display: none;
  }
  .field-error {
    bottom: -3px;
  }

  @include media("<lg") {
    padding-bottom: 5px;
  }
}

.checkbox-marker {
  display: flex;
  font-size: 30px;
  vertical-align: middle;
  flex: 0 0 30px;
  margin-right: 15px;

  .icon-check {
    fill: $disabled;

    &--active {
      fill: $site-color2;
    }
  }

  .icon-radio {
    fill: $disabled;

    &--active {
      fill: $site-color2;
    }
  }
}

.checkbox__title {
  color: $disabled;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 5px;
  @include line-height(20px, 12px);
  @include transition;

  a {
    color: $site-color2;
    @include transition;

    &:hover {
      color: $blue-dark;
    }
  }

  @include media("<lg") {
    font-size: 10px;
    @include line-height(18px, 10px);
    padding-top: 6px;
  }
}

.form-section {
  padding-bottom: 30px;

  @include media("<lg") {
    padding-bottom: 15px;
  }
}

.field-input-file {
  display: inline-block;
  width: auto;
  max-width: 100%;

  &--image {
    display: block;
    width: 170px;
    max-width: 100%;
    margin: 0 auto;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__button {
    @include transition;
    display: inline-flex;
    padding: 15px 30px;
    height: 60px;
    min-width: 170px;
    flex: 0 0 auto;
    position: relative;
    overflow: hidden;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    background-color: $blue-light;
    color: $disabled;
    @include line-height(30px, 12px);
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  &__icon-container {
    width: 45px;
    padding-right: 15px;
    flex-basis: 45px;
  }
  &__text {
    flex-basis: calc(100% - 105px);
    overflow: hidden;
    font-size: 12px;
    @include line-height(30px, 12px);
    color: $site-color2;
    font-weight: 800;
    text-transform: uppercase;
  }

  .field__data {
    &--image {
      padding-top: 100%;
    }
  }
}
.field-input-file-image {
  $this: &;

  &__container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 4px $blue-light;
    background-color: $light;

    &:hover {
      #{$this}__delete-btn {
        opacity: 1;
      }
    }
  }

  &__icon {
    font-size: 30px;
    fill: $disabled;
    margin-bottom: 15px;
  }

  &__delete-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    @include transition;
    opacity: 0;
  }
}

.position-form {
  &__add-photo {
    margin: 0 auto;
    //margin-top: -205px;
    // margin-bottom: 120px;
    margin-bottom: 30px;

    @include media("<lg") {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
}

.field-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field-set-item {
  flex-grow: 0;
  flex-shrink: 0;

  &--input {
    flex-basis: 120px;
  }
  &--checkbox {
    flex-basis: calc(100% - 120px);
  }
}

.form-text {
  font-size: 16px;
  font-weight: 600;
  @include line-height(30px, 16px);
  color: $text-color;

  &--dark {
    color: $site-color-dark;
  }

  &--with-check {
    padding-top: 15px;
  }
}
.field-group {
  position: relative;
}

.text-editor {
  &__wrapper {
    width: 100%;
    position: relative;
    padding: 15px 0 20px;
  }

  &__toolbar,
  &__content,
  .rdw-option-wrapper {
    border-radius: 0 !important;
    border: 1px solid $disabled !important;
  }

  .rdw-option-wrapper {
    width: 25px;
    height: 25px;
    padding: 0 !important;
  }

  &__toolbar {
    padding: 7px 5px 2px !important;
    margin-bottom: -1px !important;
    @include transition;
  }

  &__content {
    width: 100%;
    height: 200px !important;
    padding: 0 15px;
    @include transition;
  }

  .DraftEditor-editorContainer {
    border: none !important;
  }

  &.is-focused &__toolbar,
  &.is-focused &__content {
    border-color: $site-color2 !important;
  }

  &.is-error &__toolbar,
  &.is-error &__content {
    border-color: $red !important;
  }

  .rdw-option-active {
    border-color: $site-color2 !important;
    box-shadow: 1px 1px 0 $site-color2 !important;
  }

  .rdw-option-wrapper:hover {
    box-shadow: 1px 1px 0 $disabled;
  }

  &__error {
    color: $red;
    font-size: 10px;
    font-weight: 600;
    @include line-height(15px, 10px);
    position: absolute;
    right: 0;
    bottom: 0;
    text-transform: uppercase;
  }
}

.field-payment-container {
  padding: 15px 0 14px;
  border-bottom: 1px solid $disabled;
  position: relative;

  input {
    font-family: $Montserrat, $font-default;
  }
}

.multi-value-container {
  display: flex;
  flex-wrap: wrap;
}
.option-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  @include transition;
  cursor: pointer;
  &__withline {
    padding-left: 35px;
    align-items: flex-start;
  }
  &:hover {
    background-color: $blue-light;
  }
}

.field-with-button {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__input {
    flex-basis: calc(100% - 90px);
    position: relative;
  }
  &__button {
    flex-basis: 60px;
    position: relative;
  }
}
.field-array {
  &__row {
  }
  &__input-container {
    flex-basis: calc(100% - 180px);
    position: relative;
  }
  &__button {
    flex-basis: 90px;
    position: relative;
  }

  @include media("<md") {
    &__input-container {
      flex-basis: 100%;
    }
    &__button {
      flex-basis: 50%;
    }
  }
}

.search-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &__input {
    flex-basis: calc(100% - 30px);
  }
}
.btn-search-icon {
  fill: $disabled;

  &:hover {
    fill: $site-color2;
  }
}

.text-transform-none {
  text-transform: none;
}
