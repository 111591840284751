.header-wrapper {
  position: relative;
  height: 184px;

  @include media("<lg") {
    height: 80px;
  }
}
.header {
  position: relative;
  padding: 32px 0;
  background-color: $light;
  @include transition;

  &--sticky {
    width: 100%;
    position: fixed;
    z-index: 12;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 0;

    .main-menu__item-active::after {
      bottom: -15px;
    }
  }

  &__logo {
    width: 100%;

    @include media("<lg") {
      width: 60px;
    }
  }

  &__search {
    position: relative;
    width: 140px;
    height: 60px;
  }

  @include media("<lg") {
    padding: 10px 0;
  }
}

.header-profile {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__favorite {
    font-size: 30px;
    height: 30px;
    position: relative;
    margin-right: 45px;
  }
  &__favorite-counter {
    display: block;
    position: absolute;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    min-width: 30px;
    height: 30px;
    text-align: center;
    background-color: $site-color2;
    color: $light;
    font-size: 10px;
    font-weight: 800;
    @include line-height(20px, 10px);
    border: 5px solid $light;
  }
  &__menu-container {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__name-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__name {
    font-size: 12px;
    @include line-height(30px, 12px);
    font-weight: 800;
    color: $site-color2;
    text-transform: uppercase;
  }
  &__arr-down {
    font-size: 30px;
    fill: $disabled;
    margin-left: 15px;
  }

  &__menu {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $light;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    width: 260px;
    z-index: 1;
    // visibility: hidden;
    // opacity: 0;
    height: 0;
    overflow: hidden;
    @include transition;

    &--opened {
      height: auto;
    }
  }
  &__menu-inner {
    padding: 30px 60px;
    position: relative;
  }
  &__menu-list {
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  &__menu-item {
    margin: 0 -30px;
  }
  &__menu-link {
    padding: 15px 15px;
    display: block;
    font-size: 11px;
    @include line-height(30px, 11px);
    font-weight: 800;
    color: $disabled;
    text-transform: uppercase;
    @include transition;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: $site-color2;
      color: $light;
    }
  }
}

.main-menu {
  $this: &;

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--mobile {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
  }
  &__item {
    padding: 15px 0;
    position: relative;

    &:hover {
      #{$this}__submenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__link {
    font-size: 12px;
    @include line-height(18px, 12px);
    text-transform: uppercase;
    font-weight: 800;
    color: $site-color-dark;
    @include transition;
    display: flex;
    align-items: center;

    &:hover {
      color: $site-color;
    }
  }
  &__link-arrow {
    font-size: 30px;
    fill: $disabled;
    margin-left: 15px;
  }

  &__item-active {
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      background-color: $site-color;
      @include transition;
    }

    .main-menu__submenu {
      .main-menu__item {
        .main-menu__link {
          color: #1a1819;
        }
        &-active {
          .main-menu__link {
            color: #0ba560;
          }
        }
      }
    }

    #{$this}__link {
      color: $site-color;
    }

    @include media("<lg") {
      &::after {
        bottom: 5px;
        left: 0;
        transform: translateX(0);
      }
    }
  }

  &__item-container {
  }
  &__submenu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #fff;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    width: 260px;
    z-index: 1;
    display: block;
    opacity: 0;
    visibility: hidden;
    @include transition;

    #{$this}__item-active {
      &::after {
        bottom: 0;
        left: 0;
        transform: translateX(0%);
      }
    }
  }
  &__submenu-inner {
    padding: 30px 60px;
  }

  @include media("<lg") {
    #{$this}__submenu {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      opacity: 1;
      visibility: visible;
      background-color: transparent;
      box-shadow: none;
      padding-top: 15px;
    }
    #{$this}__submenu-inner {
      padding: 0 0 0 0;
    }
    #{$this}__item-container {
      // padding: 0;
    }
    #{$this}__item-container > #{$this}__link {
      color: $disabled;
    }
  }
}

.search {
  // width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  background-color: $light;

  &__input {
    flex-grow: 1;
    flex-shrink: 1;
    height: 60px;
    border: none;
    display: block;
    font-size: 12px;
    line-height: 30px;
    color: $site-color-dark;
    font-weight: 800;
    text-transform: uppercase;
    @include transition;
    // width: calc(100% - 60px);

    &::-webkit-input-placeholder {
      color: $disabled;
      transition: all ease 0.3s;
    }

    &:-moz-placeholder {
      color: $disabled;
      transition: all ease 0.3s;
    }

    &::-moz-placeholder {
      color: $disabled;
      transition: all ease 0.3s;
    }

    &:-ms-input-placeholder {
      color: $disabled;
      transition: all ease 0.3s;
    }

    &.field {
      width: 80px;
    }
    &.field--focused {
      width: 200px;
    }
    .field__input {
      border-bottom: none;
      padding-right: 0;
    }
  }
  &__button {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }

  @include media("<lg") {
    &__input {
      &.field--focused {
        width: 210px;
      }
    }
  }
}

.icon {
  &--search {
    fill: $disabled;
  }
  &--favorite {
    fill: $disabled;
  }
}

.menu-btn {
  padding: 11px 12px;
  height: auto;
  @include transition;
  $this: &;

  &__line {
    position: absolute;
    width: 9px;
    height: 4px;
    background-color: $disabled;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @include transition;
  }

  &::before {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    top: 0;
    left: 0;
    height: 4px;
    background-color: $disabled;
    @include transition;
  }

  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 75%;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: $disabled;
    @include transition;
  }

  &:hover {
    #{$this}__line {
      width: 100%;
    }

    &::after {
      width: 100%;
    }
  }
}

.main-menu-oerlay {
  position: fixed;
  z-index: 12;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.2s;
  &--open {
    visibility: visible;
    opacity: 1;
  }
}

.mobile-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 12;
  width: 270px;
  box-sizing: border-box;
  // overflow: auto;
  transform: translateX(-330px);
  @include transition;
  background: $light;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: stretch;

  &--profile {
    left: auto;
    right: 0;
    transform: translateX(330px);
  }

  &.open {
    transform: translateX(0);
  }
}

.mobile-menu-wrapper {
  overflow: auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.mobile-menu-inner {
  padding: 30px;
}

.mobile-menu-footer {
  padding: 30px;
  background-color: rgba($site-color2, 0.1);
}

.main-menu {
  &__close-btn {
    position: absolute;
    right: -60px;
    top: 10px;
  }
}

.profile-menu {
  &__close-btn {
    position: absolute;
    left: -60px;
    top: 0;
  }
}

.profile-btn {
  font-size: 30px;
  background-color: $site-color2;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    fill: $light;
  }
}
