.resume-info {
  
  &__photo {
    
  }
}

.data-field-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.data-field-icon {
  font-size: 30px;
  height: 30px;
  margin-right: 15px;
  flex-basis: 30px;
  flex-grow: 0;
  flex-shrink: 0;

  &--selected {
    fill: $site-color;
  }
}

.data-field {
  min-height: 60px;

  &__title {
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 800;
    color: $disabled;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    overflow: hidden;
  }
  &__text {
    font-size: 16px;
    @include line-height(20px, 16px);
    font-weight: 600;
    color: $site-color-dark;
  }

  &__top-photo {
    margin: 0 auto;
    margin-top: -205px;
    margin-bottom: 120px;
  }

  @include media("<lg") {
    &__top-photo {
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }
  @include media("<md") {
    min-height: 0px;
  }
}
.data-field-text {
  padding: 15px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: calc(100% - 45px);
  flex-grow: 1;
}
.data-field-img {
  position: relative;
  width: 170px;
  max-width: 100%;

  &__container {
    position: relative;
    padding-top: 100%;
  }
  &__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $blue-light;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__img {
    height: 100%;
    object-fit: cover;
  }
}