*,
*:before,
*:after {
  box-sizing: border-box;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

html,
body {
  min-height: 100%;

  &.is-scroll-none {
    overflow: hidden;
    height: 100%;

    body {
      overflow: hidden;
      height: 100%;
    }
  }
}

// * {
// 	-webkit-user-select: none;  /* Chrome all / Safari all */
//   -moz-user-select: none;     /* Firefox all */
//   -ms-user-select: none;      /* IE 10+ */
//   user-select: none;          /* Likely future */
// }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html {
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: $body-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font-weight: 500;
  line-height: 1.2;
  min-width: 320px;
  background: #fff;

  &.js-block-scroll {
    overflow-y: hidden;
  }
}

th {
  font-weight: inherit;
}

// font default
//-----------------------------------------------------------------------------------
body,
input,
button,
select,
textarea {
  font-family: $Montserrat, $font-default;
}

li {
  list-style-type: none;
}

// Common classes
//-----------------------------------------------------------------------------------
.clear {
  clear: both;
}

%clear-fix:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}

// text align
//-----------------------------------------------------------------------------------
.text {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

pre,
code {
  font-family: monospace;
}

a {
  display: inline-block;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

img {
  position: relative;
  display: inline-block;
  border: none;
  vertical-align: top;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  font: 300 12px / 1 $font-default;
  text-align: center;

  // &:after {
  //   content: "Broken Image of " attr(alt);
  //   display: block;
  //   color: rgb(100, 100, 100);
  //   position: absolute;
  //   z-index: 1;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #fff;
  // }
}

svg {
  pointer-events: all;
}

// form
//-----------------------------------------------------------------------------------
input,
textarea {
  appearance: none;
  box-shadow: none;
}

textarea {
  resize: none;
}

input {
  vertical-align: middle;
  margin: 0;
  padding: 0;

  ::-ms-clear,
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

button,
input[type="reset"],
input[type="submit"],
input[type="button"],
input[type="image"] {
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
}

label {
  cursor: pointer;
}

// WebKit/Blink Browsers
//-----------------------------------------------------------------------------------
.hide-mobile {
  @include media("<md") {
    display: none;
  }
}

.visible-mobile {
  @include media(">md") {
    display: none !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-body {
  flex-grow: 1;
}

.main {
  flex: 1;
}

.relative {
  position: relative;
}

.section-wrapper {
  padding: 0 0 60px 0;

  &--pad-top {
    padding-top: 60px;
  }

  @include media("<lg") {
    padding: 0 0 30px 0;

    &--pad-top {
      padding-top: 30px;
    }
  }
}
.section {
  padding: 60px 0;

  &--banner {
    position: relative;
    // padding: 0 0 160px;
    padding: 0 0 210px;
    overflow: hidden;
  }
  &--mission {
    position: relative;
  }
  &--news {
    position: relative;
  }
  &--sponsors {
    position: relative;
  }
  &--events {
    position: relative;
    overflow: hidden;
  }
  &--advertisment {
    position: relative;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 120px;
  }
  &--whoweare {
    position: relative;
    overflow: hidden;
  }
  &--benefits {
    position: relative;
    overflow: hidden;
    padding-bottom: 120px;
  }

  @include media("<lg") {
    padding: 15px 0;

    // &--banner {
    //   padding: 0 0 15px;
    // }
    &--banner {
      padding: 0 0 240px;
    }
    &--advertisment {
      padding-top: 0;
      padding-bottom: 30px;
    }
    &--sponsors {
      padding-bottom: 0;
    }
    &--mission {
      padding-left: 15px;
      padding-right: 15px;
    }
    &--benefits {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 30px;
    }
    &--whoweare {
      padding-left: 15px;
      padding-right: 15px;
    }
    &--events {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 0;
    }
    &--news {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @include media("<sm") {
    &--banner {
      padding: 0 0 290px;
    }
  }
}

.bg-blue {
  background-color: $blue-light;
}

.section-title {
  font-size: 48px;
  font-weight: 800;
  @include line-height(60px, 48px);
  margin: 0 0 60px;
  color: $site-color-dark;

  &--white {
    color: $light;
  }
  &--blue {
    color: $site-color2;
  }

  @include media("<lg") {
    font-size: 30px;
    @include line-height(40px, 30px);
    margin: 0 0 30px;
  }

  @include media("<md") {
    font-size: 20px;
    @include line-height(30px, 20px);
  }
}
.section-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 60px;

  &--center {
    justify-content: center;
  }

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60px;
    font-size: 60px;
    fill: $site-color;
    margin-right: 30px;

    &--white {
      fill: $light;
    }
  }

  &__icon-tm {
    margin-left: -30px;
    margin-right: 10px;
    font-size: 20px;
    fill: $site-color;
    flex-shrink: 0;
  }

  .section-title {
    margin: 0 0 0 0;
  }

  @include media("<lg") {
    margin: 0 0 30px;

    .section-title {
      margin: 0 0 0 0;
    }

    &__icon {
      flex-basis: 40px;
      font-size: 40px;
    }
  }
  @include media("<md") {
    &__icon {
      flex-basis: 30px;
      font-size: 30px;
      margin-right: 15px;
    }
    &__icon-tm {
      margin-left: -10px;
      margin-right: 10px;
      font-size: 10px;
      flex-shrink: 0;
    }
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.uppercase {
  text-transform: uppercase;
}

.btn--play {
  width: 60px;
  height: 60px;
  position: relative;

  &::before {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $light;
    opacity: 0.2;
    display: block;
    content: "";
  }

  &::after {
    display: block;
    content: "";
    border: 5px solid transparent;
    border-left: 6px solid $light;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-25%, -50%);
  }
}

.content-block {
  a:not([class]) {
    text-decoration: underline;
    color: inherit;
  }
  .link {
    color: $site-color2;
    text-decoration: underline;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  button:not([class]):not([type]) {
    font-size: initial;
    font-weight: 600;
    text-decoration: underline;
    text-align: initial;
    color: inherit;
  }

  ul {
    padding: 0 0 15px 30px;
    ul {
      padding: 30px 0 0 30px;
    }

    li {
      padding: 0 0 0 30px;
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
      @include line-height(30px, 16px);
      margin: 0 0 15px 0;
      position: relative;
      word-break: break-word;

      &::before {
        position: absolute;
        content: "•";
        display: block;
        font-size: 30px;
        font-weight: 600;
        @include line-height(30px, 30px);
        color: $site-color2;
        left: 0;
      }

      @include media("<lg") {
        font-size: 14px;
        @include line-height(30px, 14px);
        margin: 0 0 15px 0;
      }
    }

    .ql-indent-1 {
      margin-left: 30px;

      &::before {
        content: "○";
        font-size: 20px;
        top: 5px;
      }
    }
    .ql-indent-2 {
      margin-left: 60px;

      &::before {
        content: "-";
      }
    }

    @include media("<lg") {
      padding: 0 0 15px 0;

      ul {
        padding: 15px 0 0 15px;
      }
      .ql-indent-1 {
        margin-left: 15px;
      }
      .ql-indent-2 {
        margin-left: 30px;
      }
    }
  }

  ol {
    padding: 0 0 15px 30px;
    counter-reset: counter;

    li {
      padding: 0 0 0 0;
      font-size: 16px;
      font-weight: 600;
      color: $text-color;
      @include line-height(30px, 16px);
      margin: 0 0 15px 0;
      position: relative;
      word-break: break-word;

      &:before {
        counter-increment: counter;
        content: counter(counter) ".";
        margin-right: 5px;
        min-width: 25px;
        display: inline-block;
      }

      @include media("<lg") {
        font-size: 14px;
        @include line-height(30px, 14px);
        margin-bottom: 15px;
      }
    }

    .ql-indent-1 {
      margin-left: 30px;

      &:before {
        content: counter(counter) ")";
      }
    }
    .ql-indent-2 {
      margin-left: 60px;
    }

    @include media("<lg") {
      padding: 0 0 15px 0;

      .ql-indent-1 {
        margin-left: 15px;
      }
      .ql-indent-2 {
        margin-left: 30px;
      }
    }
  }

  p {
    font-size: 16px;
    @include line-height(30px, 16px);
    color: $text-color;
    font-weight: 600;
    margin-bottom: 15px;
    word-break: break-word;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
    &.text-center {
      text-align: center;
      margin-bottom: 0;
    }
  }

  p {
    &.ql-indent-1 {
      margin-left: 60px;
    }
    &.ql-indent-2 {
      margin-left: 90px;
    }
    &.ql-indent-3 {
      margin-left: 120px;
    }
  }

  @include media("<lg") {
    p {
      &.ql-indent-1 {
        margin-left: 45px;
      }
      &.ql-indent-2 {
        margin-left: 45px;
      }
      &.ql-indent-3 {
        margin-left: 60px;
      }
    }
  }

  .show-more-text > div {
    font-size: 16px;
    @include line-height(30px, 16px);
    color: $text-color;
    font-weight: 600;
    margin-bottom: 30px;
    word-break: break-word;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }

  //a{
  //  color: $site-color2;
  //  //text-decoration: underline;
  //  text-decoration: none;
  //
  //  &:hover {
  //    color: $blue-dark;
  //    text-decoration: none;
  //  }
  //}

  b,
  strong {
    font-weight: 800;
  }

  i,
  em {
    font-style: italic;
  }

  h2 {
    font-size: 20px;
    @include line-height(30px, 20px);
    font-weight: 800;
    color: $site-color-dark;
    margin-bottom: 30px;

    @include media("<lg") {
      font-size: 16px;
      @include line-height(30px, 14px);
    }
  }
  h3 {
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 800;
    color: $site-color-dark;
    margin-bottom: 30px;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }
  h4 {
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 600;
    color: $site-color-dark;
    margin-bottom: 30px;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }
  h5 {
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 800;
    color: $text-color;
    margin-bottom: 30px;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }

  hr {
    display: block;
    width: 100%;
    height: 0;
    line-height: 0;
    border: none;
    border-bottom: 4px solid $blue-light;
    margin: 0 0 30px;
  }

  table {
    border: 4px solid $blue-light;
    width: 100%;
    margin-bottom: 30px;
  }
  td {
    padding: 15px;
    min-height: 90px;
    vertical-align: middle;
    text-align: left;
    color: $site-color-dark;
    font-size: 16px;
    font-weight: 600;
    @include line-height(30px, 16px);

    .date {
      font-size: 12px;
      font-weight: 800;
      color: $disabled;
      text-transform: uppercase;
    }
    .title {
      font-size: 12px;
      font-weight: 800;
      color: $site-color2;
      text-transform: uppercase;
    }

    .btn {
      min-width: 120px;
    }
  }
  tr:nth-of-type(odd) {
    td {
      background-color: $blue-light;
    }
  }
  th,
  tr:first-child td {
    background-color: $site-color-dark;
    color: $light;
    padding: 15px;
    min-height: 60px;
    vertical-align: middle;
    text-align: left;
    font-size: 10px;
    font-weight: 800;
    @include line-height(20px, 10px);
    text-transform: uppercase;
  }

  blockquote {
    margin: 60px 0;
    position: relative;
    background-color: $light;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.05);
    padding: 60px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    @include line-height(30px, 16px);
    font-style: italic;
    color: $text-color;

    &::before {
      content: "”";
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $site-color2;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $light;
      font-size: 40px;
      @include line-height(40px, 40px);
    }

    @include media("<md") {
      padding: 20px;
      font-size: 14px;
      margin: 30px 0;

      &::before {
        width: 40px;
        height: 40px;
        font-size: 28px;
      }
    }
  }
  .career-block {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.benefits-navigations {
  position: relative;
  padding: 60px 0 60px 60px;

  &::before {
    display: block;
    position: absolute;
    content: "";
    background-color: $site-color2;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;

    @include media("<lg") {
      left: -30px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include media("<lg") {
    padding: 30px 0;
    margin-bottom: 30px;
  }
}

.benefits-thumbs {
  // height: 360px;
  margin-bottom: 60px;
  @include media("<lg") {
    margin-bottom: 30px;
  }
}
.benefits-thumbs-slide {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  $this: &;

  &__text {
    font-size: 16px;
    font-weight: 800;
    @include line-height(30px, 16px);
    color: $light;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(20px, 14px);
    }
  }

  &.swiper-slide-thumb-active {
    background-color: $light;

    #{$this}__text {
      color: $site-color2;
    }
  }

  @include media("<lg") {
    padding: 15px 30px;
  }
}

.page-title-container {
  background-color: $blue-light;
  padding: 120px 0;

  @include media("<lg") {
    padding: 30px 15px;
  }
}

.page-title {
  font-size: 48px;
  font-weight: 800;
  white-space: pre-line;
  @include line-height(60px, 48px);
  margin: 0 0;
  color: $site-color-dark;

  @include media("<lg") {
    font-size: 30px;
    @include line-height(40px, 30px);
    word-break: normal;
  }
  @include media("<sm") {
    font-size: 20px;
  }
}

.page-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0;

  &--center {
    justify-content: center;
  }

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60px;
    font-size: 60px;
    fill: $site-color;
    margin-right: 30px;
  }

  @include media("<lg") {
    &__icon {
      flex-basis: 40px;
      font-size: 40px;
    }
  }
  @include media("<sm") {
    &__icon {
      flex-basis: 30px;
      font-size: 30px;
      margin-right: 20px;
    }
  }
}

.page-title-registration {
  position: absolute;
  top: -90px;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__text {
    font-weight: 800;
    color: $disabled;
    font-size: 12px;
    @include line-height(20px, 12px);
    text-transform: uppercase;
    margin-right: 30px;
  }

  @include media("<lg") {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 30px;
    justify-content: center;
  }

  @include media("<lg") {
    flex-direction: column;

    &__text {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

.content-container {
  position: relative;
  padding: 120px 0;

  @include media("<lg") {
    padding: 30px 15px;
  }
}

.content-container__sm {
  position: relative;
  padding: 60px 0;

  @include media("<lg") {
    padding: 30px 15px;
  }
}

.section-title {
  &__sign {
    color: $red;
    font-size: 13px;
    @include line-height(10px, 13px);
    display: inline-block;
    width: 10px;
    text-align: center;
    font-weight: 800;
    // margin-top: -10px;
  }
}

.section-title-md {
  font-size: 16px;
  @include line-height(30px, 16px);
  font-weight: 800;
  margin: 0 0 30px 0;
  padding: 0 0;
  color: $site-color-dark;
  position: relative;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.text-center {
    justify-content: center;
  }
}

.section-title-lg {
  font-size: 20px;
  @include line-height(30px, 20px);
  font-weight: 800;
  margin: 0 0 30px 0;
  padding: 0 0;
  color: $site-color-dark;
  position: relative;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;

  &--blue {
    color: $site-color2;
  }
}

.title-required {
  padding: 20px 0;
  font-weight: 600;
  font-size: 10px;
  @include line-height(20px, 10px);
  color: $disabled;
  text-transform: uppercase;

  &__sign {
    color: $red;
    font-size: 13px;
    @include line-height(10px, 13px);
    display: inline-block;
    width: 10px;
    text-align: center;
    font-weight: 800;
  }

  @include media("<lg") {
    padding: 7px 0;
  }
}

.title-annotation {
  font-weight: 600;
  font-size: 10px;
  @include line-height(20px, 10px);
  color: $disabled;
  text-transform: uppercase;

  &--error {
    color: $red;
  }
}

.category-link {
  $this: &;
  display: block;
  position: relative;
  background-color: $light;
  padding: 4px;
  width: 100%;
  margin-bottom: 30px;
  @include transition;

  &::after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: solid 4px $blue-light;
    @include transition;
  }

  &__icon {
    fill: $site-color2;
    font-size: 30px;
    @include transition;
    opacity: 0;
    visibility: hidden;
    // display: none;
  }

  ul {
    padding-bottom: 0;
  }

  &:hover {
    &::after {
      bottom: -30px;
      border: solid 4px $light;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);

      @include media("<lg") {
        bottom: 0;
      }
    }

    #{$this}__icon {
      // font-size: 30px;
      opacity: 1;
      visibility: visible;
    }

    .category-title {
      color: $site-color2;
    }
  }
}

.category-link-container {
  position: relative;
  z-index: 1;
  padding: 60px 60px 0;

  @include media("<lg") {
    padding: 30px 30px 15px;
  }
}

.category-title {
  padding: 0;
}

.numbering-container {
}
.numbering-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;
}
.numbering-item {
  margin: 0 0 15px 0;
}
.number-item {
  border-radius: 50%;
  font-size: 12px;
  font-weight: 800;
  @include line-height(20px, 12px);
  color: $disabled;
  background-color: $blue-light;
  width: 60px;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.metadata {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px 15px;

  & > * {
    margin: 15px;
  }

  &_label {
    padding: 8px 15px;
    background-color: $site-color;
    font-size: 10px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 800;
    color: $light;
  }

  &_date {
    font-size: 12px;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 800;
    color: $disabled;
  }
}

.section-title-extended {
  margin-bottom: 30px;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    margin-bottom: 0;
  }
  &__data {
    color: $disabled;
    font-size: 10px;
    @include line-height(30px, 10px);
    font-weight: 800;
    text-transform: uppercase;
    margin-left: 30px;
    flex-shrink: 0;
  }

  &__border {
    border-bottom: 1px solid $gray-light;
    margin-top: 29px;
  }

  @include media("<md") {
    &__row {
      display: block;
    }
    &__text {
      margin-bottom: 0;
    }
    &__data {
      margin-left: 0;
    }
  }
}
.mt-1 {
  margin-top: 12px;
}

.banner-links {
  &-wrap {
    width: 770px;
    height: 310px;
    display: flex;
    position: absolute;
    right: 375px;
    bottom: 0;

    @include media("<xl") {
      right: 110px;
    }
    @include media("<lg") {
      width: 100%;
      right: 0;
      bottom: 120px;
      display: block;
      height: 120px;
    }

    @include media("<sm") {
      height: 145px;
      bottom: 145px;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      color: $light;
      padding: 60px;

      @include media("<lg") {
        padding: 10px;
      }
      @include media("<sm") {
        padding: 10px;
      }

      &.banner-links_col-green {
        background-color: $site-color;
      }
      &.banner-links_col-blue {
        background-color: $site-color2;
      }
    }
  }

  &__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 24px;
    width: 230px;

    @include media("<lg") {
      margin-bottom: 10px;
      width: 83%;
    }
    @include media("<md") {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
  }
  &__text {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    @include media("<lg") {
      max-width: 85%;
    }
    @include media("<md") {
      line-height: 22px;
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($light, 0.2);
  }
  &__icon {
    font-size: 30px;
    fill: $light;
  }
}

.util-fill-white {
  fill: $light !important;
}

.util-fill-green {
  fill: $site-color !important;
}

.util-fill-blue {
  fill: $site-color2 !important;
}

.banner-images {
  margin-top: 30px;

  // p {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;

  //   img {
  //     min-width: 25%;
  //     padding: 5px;
  //     @include media("<md") {
  //       min-width: 50%;
  //     }

  //     @media screen and (max-width: 380px) {
  //       min-width: 100%;
  //     }
  //   }
  // }
}
