.left-bar-container {
  position: relative;
  padding-right: 30px;

  @include media("<lg") {
    padding-bottom: 30px;
    padding-right: 0px;
  }
}

.left-menu {
  position: relative;

  &__item {
    position: relative;
    margin-bottom: 30px;
    padding: 15px 0;

    @include media("<lg") {
      margin-bottom: 15px;
      padding: 5px 0px;
    }
  }
  &__link {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 800;
    color: $site-color-dark;
    width: 100%;

    &:hover {
      color: $site-color2;
    }

    @include media("<lg") {

    }
  }
  &__link--active {
    color: $site-color;

    &:hover {
      color: $site-color;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      bottom: -15px;
      left: 0;
      background-color: $site-color;

      @include media("<lg") {
        bottom: -5px;
      }
    }
  }
  &__text {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 30px);
    text-align: left;
  }
  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 30px;
    fill: $disabled;
  }
}

.choose-button {
  position: relative;
  display: flex;
  width: 100%;
  border: solid 4px $blue-light;
  background-color: $light;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  height: 270px;

  &__ico {
    font-size: 30px;
    margin-top: 15px;
    fill: #5d656b;
  }
  &__text {
    text-align: center;
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    @include line-height(15px, 10px);
    color: #5d656b;
  }
  &__small {
    position: absolute;
    bottom: 26px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    color: #a0adb8;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &:hover {
    border-color: $light;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  }
}

.total-price {
  min-height: 60px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;

  &__container {
    flex-basis: 100%;
  }

  &__title {
    font-size: 10px;
    text-transform: uppercase;
    color: $disabled;
    @include line-height(15px, 10px);
    font-weight: 800;
  }
  &__per-candidate {
    font-size: 10px;
    text-transform: uppercase;
    color: $disabled;
    @include line-height(15px, 10px);
    font-weight: 800;
    text-align: right;
  }
  &__number {
    font-size: 20px;
    color: $site-color-dark;
    @include line-height(30px, 20px);
    font-weight: 800;
    margin-left: 30px;
    position: relative;
  }
  &__old {
    color: $disabled;
    font-size: 12px;
    @include line-height(12px, 12px);
    text-decoration: line-through;
    position: absolute;
    right: 0;
    top: -12px;
  }
}

.card-menu {

  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &__link {
    padding: 0;
    background-color: transparent;
    font-size: 12px;
    @include line-height(30px, 12px);
    font-weight: 800;
    color: $disabled;
    min-width: 0;
    height: auto;
    width: 100%;
    &:hover {
      background-color: transparent;
      color: $site-color-dark;
    }
  }
}

.resumes-list {
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &__header {
      font-size: 24px;
      font-weight: 800;
      text-align: center;
      color: $site-color-dark;
      margin-left: auto;
    }
  }
}