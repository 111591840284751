.card-item {
  border: solid 4px $blue-light;
  background-color: $light;
  padding: 56px;
  @include transition;
  display: block;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  $this: &;


  &__label {
    position: absolute;
    top: -4px;
    padding: 7px 15px 8px;
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 800;
    text-transform: uppercase;
  }
  &__label-right {
    right: 56px;
  }
  &__label-yellow {
    background-color: $yellow;
    color: $light;
  }
  &__label-red {
    background-color: $red;
    color: $light;
  }
  &__label-green {
    background-color: $site-color;
    color: $light;
  }
  &__label-blue {
    background-color: $site-color2;
    color: $light;
  }
  &__label-orange {
    background-color: $orange;
    color: $light;
  }
  &__label-disabled {
    background-color: $disabled;
    color: $light;
  }

  &__field {
    margin-bottom: 30px;
    min-height: 60px;
  }
  &__field-title {
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 800;
    text-transform: uppercase;
    color: $disabled;
  }
  &__field-text {
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 600;
    color: $site-color-dark;

    p {
      margin-bottom: 30px;
      word-wrap: break-word;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: 800;
    @include line-height(30px, 20px);
    color: $site-color-dark;
    @include transition;
    word-break: break-word;

    &--sm {
      font-size: 16px;
      @include line-height(30px, 16px);
    }
  }
  .last-row{
    #{$this}__field {
      margin-bottom: 0;
    }
  }

  &__title-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__title {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 30px);
    width: calc(100% - 30px);
  }
  &__menu {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30px;
    width: 30px;
  }
  &__menu-btn {
    font-size: 30px;
    @include line-height(30px, 30px);
    height: 30px;
  }
  &__menu-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 180px;
    background-color: $light;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
    padding: 60px 30px;
    z-index: 1;
  }
  &__menu-ico {
    fill: $disabled;
  }
  &__dates {
    position: absolute;
    //bottom: 10px;
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 600;
    text-transform: uppercase;
    color: $disabled;
    left: 0;
    width: 100%;
    text-align: center;
    &__subText {
      text-transform: none;
      margin: 5px 0;
      font-size: 12px;
      .card-item__date__subLink {
        font-size: 12px;
        text-decoration: none;
        color: #a0adb8;
        &:hover {
          @include transition;
          color: $site-color2;
        }
        span {
          text-decoration: underline;
          font-weight: 900;
        }
      }
    }

  }
  &__link {
    @include transition;
    cursor: pointer;
  }
  &__price {
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    background-color: $blue-light;
    font-size: 20px;
    @include line-height(30px, 20px);
    font-weight: 800;
    color: $site-color-dark;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__field-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;

    .icon {
      fill: $disabled;
    }
    .icon--active {
      fill: $blue-dark;
    }
  }

  &:hover {
    border-color: $light;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);

    #{$this}__name:not(p) {
      color: $site-color2;
    }
  }

  @include media("<md") {
    padding: 26px;

    &__label {
      position: relative;
      top: auto;
      margin-bottom: 15px;
      display: inline-block;
    }
    &__label-right {
      right: auto;
    }
    &__field {
      min-height: 0;
    }
    &__dates {
      position: relative;
      bottom: auto;
      margin-top: 15px;
    }
    &__name {
      font-size: 16px;
    }
    &__field-text {
      @include line-height(22px, 16px);
    }

    .last-row {
      #{$this}__field {
        margin-bottom: 30px;
      }
      & > #{$this}__field:last-child {
        margin-bottom: 0;
      }
      .col-12:last-child {
        #{$this}__field {
          margin-bottom: 0;
        }
      }
    }
  }
}
.field-text--l-20 {
  @include line-height(20px, 16px);
  margin-top: 5px;
}
.show-more-container {
  max-height: 90px;
  overflow: hidden;
}
.show-more-button {
  background-color: $light;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  @include line-height(30px, 12px);
  color: $disabled;
  @include transition;

  &:hover {
    color: $site-color-dark;
  }

  &--forum {
    display: none;
  }
}

.product-item {

  &__photo-container {
    flex-basis: 180px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 150px;
    width: 180px;
  }
  &__photo {
    object-fit: cover;
    height: 100%;
  }
  &__photo-bg {
    background-color: $blue-light;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__logo {
    font-size: 30px;
    fill: $site-color;
  }
  &__header {
    flex-basis: calc(100% - 180px);
    width: calc(100% - 180px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  @include media("<md") {
    padding: 26px;

    &__photo-container {
      margin-bottom: 30px;
    }
    &__header {
      flex-basis: 100%;
      width: 100%;
    }
  }
}

.date-expired {
  color: $red;
}


.card-profile {
  border: solid 4px $blue-light;
  background-color: $light;
  padding: 56px;
  @include transition;
  display: block;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  $this: &;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__photo-container {
    margin-right: 30px;
    flex-basis: 60px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 60px;
    width: 60px;
  }
  &__photo {
    height: 60px;
    width: 100%;
    object-fit: cover;
  }
  &__check {
    font-size: 30px;
    @include line-height(30px, 30px);
    height: 30px;
    flex-basis: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 30px;
  }
  &__check-ico {
    fill: $disabled;
  }
  &__check-ico--active {
    fill: $site-color2;
  }

  &__name-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // width: calc(100% - 90px);
  }

  &__favorite {
    flex-basis: 30px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__favorite-btn {
    font-size: 30px;
    @include line-height(30px, 30px);
    height: 30px;
  }
  &__favorite-ico {
    fill: $disabled;
  }
  &__favorite-btn--active {
    .card-profile__favorite-ico {
      fill: $site-color2;
    }
  }
  &__checked {
    font-size: 30px;
    margin-left: 15px;
    flex-basis: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    fill: $site-color2;
  }

  &__requirements {
    flex-basis: calc(100% - 60px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__needing {}
  &__compensation {
    flex-basis: calc(100% - 90px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__price {
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    background-color: $blue-light;
    font-size: 20px;
    @include line-height(30px, 20px);
    font-weight: 800;
    color: $site-color-dark;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__agreement-ico {
    font-size: 30px;
  }

  @include media("<md") {
    padding: 26px;

    &__header {
      flex-direction: column;
      align-items: center;
    }
    &__photo-container {
      margin-right: 0;
      margin-bottom: 45px;
    }
    &__name-container {
      margin-bottom: 45px;
    }
    .row:last-child {
      #{$this}__field {
        margin-bottom: 30px;
      }
      .col-12:last-child #{$this}__field {
        margin-bottom: 0;
      }
    }
  }
}

.favorite-card {}
