.dropbox {
  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
  }

  &__title {
    flex-grow: 1;
    flex-shrink: 1;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-align: left;
    letter-spacing: normal;
    color: $site-color-dark;
  }
  &__title-sm {
    font-size: 16px;
  }
  &__pay {
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 800;
    color: $disabled;
    text-align: right;
    flex-shrink: 0;
  }

  &__icon {
    flex-shrink: 0;
    margin-left: 15px;
    font-size: 30px;
    fill: $disabled;
  }

  &__content {
    display: none;
    padding: 15px 0;
  }
  &__content--form {
    padding: 30px 0 0;
  }
  &__content--active {
    display: block;
  }

  &__more {
    width: 100%;
    height: 60px;
    padding: 15px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    text-transform: uppercase;
    letter-spacing: normal;
    color: $disabled;

    @include transition;

    &:hover,
    &:active {
      color: $site-color-dark;
    }
  }

  &__border-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    flex-direction: column;
  }

  &__border{
    border-bottom: 1px solid $disabled;
    width: 100%;
  }

  // &--active &__content {
  //   display: block;
  // }
}
