.right-side {
  padding-left: 30px;
  position: relative;

  @include media("<lg") {
    padding-left: 0px;
  }
}

.adwert-image {
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  &__img {
    display: block;
  }
}

.featured {
}
.featured-list {
}
.featured-item {
  position: relative;
  border: solid 1px $yellow;
  padding: 60px;
  margin-bottom: 30px;
  $this: &;

  &__title {
    font-size: 20px;
    font-weight: 800;
    @include line-height(30px, 20px);
    color: $site-color-dark;
    margin-bottom: 30px;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__name {
    color: $site-color-dark;
  }

  &__field {
    margin-bottom: 30px;
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__field-title {
    font-size: 10px;
    @include line-height(15px, 10px);
    font-weight: 800;
    text-transform: uppercase;
    color: $disabled;
  }
  &__field-text {
    font-size: 16px;
    @include line-height(30px, 16px);
    font-weight: 600;
    color: $site-color-dark;
  }

  &:hover {
    #{$this}__name {
      color: $site-color2;

      &:hover {
        color: $blue-dark;
      }
    }
  }

  @include media("<md") {
    padding: 29px;
  }
}

.product-list {
}

.geography_address {
  font-size: 16px;
  font-weight: 800;
  color: #1a1819;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}
