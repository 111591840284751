.social-links {
  position: relative;

  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  &__item {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__link {
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 50%;
    @include transition;

    &:hover {
      .icon{
        fill: #fff;

        .youtube-play {
          fill: #F31A11 !important;
        }
      }

      .icon--instagram {
        path {
          fill: #fff;
        }

        &.icon--white {
          path {
            fill: $site-color2;
          }
        }
      }

      .icon--white {
        fill: $site-color2;

        .youtube-play {
          fill: #fff!important;
        }
      }
    }
  }

  &__link-facebook{
    &:hover {
      background-color: #4961B5;
    }
  }
  &__link-linkedin{
    &:hover {
      background-color: #4888F8;
    }
  }
  &__link-twitter{
    &:hover {
      background-color: #1D1D1B;
    }
  }
  &__link-youtube{
    &:hover {
      background-color: #F31A11;
    }
  }
  &__link-instagram{
    &:hover {
      background-image: linear-gradient(166deg, #4e60d3 5%, #913baf 20%, #d52d88 84%, #f26d4f 109%);
    }
  }

  &__link-white {
    &:hover {
      background-color: #fff;
      background-image: none;
    }
  }
}

.icon {
  &--facebook{
    fill: #4961B5;
  }
  &--linkedin{
    fill: #4888F8;
  }
  &--twitter{
    fill: #1D1D1B;
  }
  &--youtube{
    fill: #F31A11;

    &.icon--white {
      .youtube-play {
        fill: $site-color2;
      }
    }
  }
  &--instagram{
    &.icon--white {
      path {
        fill: #fff;
      }
    }
  }

  &--white {
    fill: #fff;
  }
}