.ReactModal {
  $this: &;
  &__Overlay {
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 0;
    text-align: center;
    overflow: auto;
    padding: 20px 10px;
    &:after {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      content: "";
    }
  }
  &__Body--open {
    overflow: hidden;
  }
  &__Content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
  }
}

.modal {
  padding: 40px;
  text-align: center;
  word-break: break-word;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.05);
  background-color: $light;
  width: 560px;
  position: relative;

  @include media("<lg") {
    padding: 30px;
  }

  &-content {
    font-size: 16px;
    @include line-height(30px, 16px);
    color: $text-color;
    font-weight: 500;
    white-space: pre-line;

    @include media("<lg") {
      font-size: 14px;
      @include line-height(30px, 14px);
    }
  }

  &-title {
    font-size: 20px;
    @include line-height(30px, 20px);
    font-weight: 700;

    @include media("<lg") {
      font-size: 18px;
      @include line-height(30px, 18px);
    }
  }

  &-content > * + *,
  &-body > * + * {
    margin-top: 15px;
  }

  &-close {
    font-size: 30px;
    position: absolute;
    height: 30px;
    top: 0;
    right: 0px;

    &__icon {
      fill: $site-color2;
      @include transition;

      &:hover {
        fill: $blue-dark;
      }
    }
  }
}
