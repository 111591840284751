.footer {
  position: relative;
  padding: 60px 0 60px 0;
  background-position: center;
  background-size: cover;
  background-image: url("/img/footer.jpg");

  &-body {
    padding: 60px 0;
    margin-bottom: 60px;
  }

  &_image {
    height: 330px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .social-links__list {
    justify-content: flex-end;
  }

  @include media("<lg") {
    padding: 15px 15px 15px 15px;

    &-body {
      padding: 15px 0;
      margin-bottom: 30px;
    }

    .social-links__list {
      justify-content: center;
    }

    .social-links__link {
      padding: 10px;
    }
  }
}

.bottom {
  padding: 0 0;
  border-top: 1px solid rgba($light, 0.1);
}

.copyright {
  opacity: 0.5;
  font-size: 10px;
  font-weight: 800;
  @include line-height(15px, 10px);
  text-transform: uppercase;
  color: $light;
  padding: 15px 0;
}

.footer-menu {
  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &__link {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 800;
    color: $light;
    @include line-height(30px, 12px);
  }

  @include media("<lg") {
    &__list {
      justify-content: center;
    }
  }

  @include media("<sm") {
    &__list {
      flex-direction: column;
    }
  }
}
