.DayPicker-wrapper {
  padding-top: 20px;
}
.DayPicker {
  display: block;
  margin-bottom: 30px;
}
.DayPicker-NavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 15px 0;

  @include media("<md") {
    height: 70px;
  }
}
.DayPicker-NavButton {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  @include line-height(20px, 20px);
  @include transition();
  font-weight: 600;
  color: $disabled;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: $blue-light;
  }

  &::after {
    display: block;
    font-family: swiper-icons;
    font-size: 12px;
  }

  @include media("<md") {
    width: 40px;
    height: 40px;
  }
}
.DayPicker-NavButton--prev {
  &::after {
    // content: "<";
    content: "prev";
  }
}
.DayPicker-NavButton--next {
  &::after {
    // content: ">";
    content: "next";
  }
}
.DayPicker-Months {
  position: relative;
}
.DayPicker-Month {
}
.DayPicker-Caption {
  position: absolute;
  height: 100px;
  top: -100px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  @include line-height(20px, 12px);
  font-weight: 800;
  color: $disabled;
  text-transform: uppercase;

  @include media("<md") {
    height: 70px;
    top: -70px;
  }
}
.DayPicker-Weekdays {
  position: absolute;
  height: 20px;
  top: -120px;
  left: 0;
  width: 100%;
  height: 20px;

  @include media("<md") {
    top: -90px;
  }
}
.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.DayPicker-Weekday {
  flex-grow: 0;
  flex-basis: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  @include line-height(20px, 10px);
  font-weight: 600;
  text-transform: uppercase;
  color: $site-color-dark;

  abbr {
    text-decoration: none;
  }

  @include media("<md") {
    flex-basis: 35px;
  }
}
.DayPicker-Body {
}
.DayPicker-Week {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8d8d8;
}
.DayPicker-Day {
  flex-grow: 0;
  // flex-basis: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  cursor: pointer;
  position: relative;

  // &:hover {
  //   .DayPicker-Day-Date {
  //     background-color: $blue-light;
  //   }
  // }

  &--selected {
    .DayPicker-Day-Date {
      background-color: $site-color2;
      color: $light;

      &__first-day {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          content: "";
          height: 100%;
          background-color: $site-color2;
          width: calc((100% - 40px) / 2);
        }
      }

      &__last-day {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          height: 100%;
          background-color: $site-color2;
          width: calc((100% - 40px) / 2);
        }
      }
    }

    .DayPicker-Day-Container {
      position: relative;

      .DayPicker-Day-Date__day-between {
        border-radius: 0;
        width: 100%;
      }
    }
  }

  &--today {
    .DayPicker-Day-Date {
      color: $red;
    }
  }

  &--disabled {
    .DayPicker-Day-Date {
      color: $disabled;
    }
  }

  @include media("<md") {
    flex-basis: 35px;
    height: 35px;
  }
}
.DayPicker-Day--outside {
}

.DayPicker-Day-Container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayPicker-Day-Date {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  @include line-height(20px, 12px);
  font-weight: 800;
  color: $black;
  // @include transition();

  @include media("<md") {
    width: 30px;
    height: 30px;
  }
}
.DayPicker-Day-EventMarker {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: $green;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;

  @include media("<md") {
    width: 5px;
    height: 5px;
    bottom: 5px;
  }
}
